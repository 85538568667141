body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.selectAccount .MuiInputBase-input {
  padding: 0px !important;
}
.selectAccount .MuiFormLabel-root {
  line-height: 0;
}
.noData {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
}
.searchInput .MuiIconButton-root {
  padding: 0 !important;
}

.uploaderMainContainer {
  height: 50px;
  width: 100%;
  border: 2px dashed #9a9a9a;
  background: #93a2fc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
}
.mgTop12 {
  margin-top: 12px !important;
}
.displayFlex {
  display: flex;
}

.margin8 {
  margin: 8px !important;
}

.fullWidth {
  width: 100%;
}

/*------------- Arena Date Picker Starts --------------*/

.arenaDatePickerMainContainer {
  border: 1px solid #9a9a9a;
  padding: 8px 8px;
  border-radius: 8px;
  background: #93a2fc;
}

.arenaDatePickerMainContainer .MuiFormControl-root {
  width: 100%;
}
.arenaDatePickerMainContainer .MuiInputBase-root {
  display: flex;
  flex-direction: row-reverse;
  color: #fff !important;
}

.arenaDatePickerMainContainer .date-picker-dialog {
  text-align: end !important;
  color: #ffffff !important;
}
.arenaDatePickerMainContainer .MuiInput-underline:before {
  display: none !important;
}
.arenaDatePickerMainContainer .MuiInput-underline:after {
  display: none !important;
}
.arenaDatePickerMainContainer .MuiIconButton-label {
  color: #ffffff !important;
}

/*------------- Arena Date Picker Ends --------------*/

.halfFlex {
  flex: 0.5;
}

/* ------------------ Arena Dropdown starts ----------*/
.arena-dropdown__menu {
  min-width: 200px;
  z-index: 9999999 !important;
  overflow: visible;
}

.arena-dropdown__menu-list {
  background: #fff !important;
}
.arena-dropdown__control {
  display: flex;
  background: #fff !important;
  height: 100%;
  border-radius: 8px !important;
  border: 1px solid #9a9a9a !important;
}
.arena-dropdown__menu-list {
  border: 1px solid #9a9a9a !important;
  border-radius: 8px;
}
.arena-dropdown__placeholder {
  color: #000 !important;
}
.arena-dropdown__option {
  color: #000 !important;
  background: #fff !important;
}
.arena-dropdown__option:hover,
.arena-dropdown__option:focus,
.arena-dropdown__option:active,
.arena-dropdown__option:visited {
  background: #fff !important;
  opacity: 0.5 !important;
}
.arena-dropdown__input #react-select-2-input {
  color: #000 !important;
}
.arena-dropdown__single-value {
  color: #000 !important;
}
.arena-dropdown__indicator-separator {
  display: none;
}

.dropDownIcon {
  color: #000 !important;
  margin-right: 12px !important;
  height: 20px !important;
  width: 32px !important;
}

.dropDownIconsContainer {
  padding: 16px 8px !important;
  background: transparent;
  display: flex;
}

/* ------------------ Arena Dropdown ends ----------*/

.flex1 {
  flex: 1;
}

.mgTop8 {
  margin-top: 8px !important;
}

.conclusionDropdown .margin8 {
  margin: 0 !important;
}

.conclusionDropdown {
  margin: 8px;
  width: 100%;
}

#conclusionHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.noMargin {
  margin: 0 !important;
}

#imageContainer {
  max-width: 250px;
}

.uploadedImageContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px;
}

#commentSingleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bold {
  font-weight: bold !important;
}

.alignCenter {
  align-self: center !important;
}

#commentModalMainContainer .MuiDialog-paperScrollPaper {
  height: 500px;
}

::-webkit-scrollbar {
  width: 0px !important;
  background: transparent; /* Chrome/Safari/Webkit */
}

#closeSubjectCommentIcon {
  position: absolute;
  right: 4px;
  top: 4px;
}

#commentModalTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.customHeight .MuiInputBase-root {
  height: 40px;
}

.noPadding {
  padding: 0 !important;
}

.minWidthInitial {
  min-width: initial !important;
}

#imageContainer .uploaderMainContainer {
  width: 100px;
  background-color: #fff;
  height: initial;
}

.mgLeft8 {
  margin-left: 8px !important;
}

#editDivider {
  height: 24px !important;
}

.justifyContentSpaceBetween {
  justify-content: space-between !important;
}
#csvDataContainer {
  margin: 8px;
  border: 1px solid #000;
  padding: 8px;
}
.mgBottom8 {
  margin-bottom: 8px !important;
}

.loadingContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.displayNone {
  display: none !important;
}

.grecaptcha-badge {
  position: relative;
  left: 0;
  z-index: 9999;
  bottom: 0;
}
.chipInputContainer {
  width: 100%;
  margin: 8px;
}
.chipInput {
  width: 100%;
  padding: 9px;
}
